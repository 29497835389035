<template>
  <el-main>
    <el-form class="el-form-search" label-width="100px">
      <el-form-item label="二维码ID：">
        <el-input size="small" v-model="searchForm.sn" placeholder="请输入二维码ID"></el-input>
      </el-form-item>
      <el-form-item label="商家姓名：">
        <el-input size="small" v-model="searchForm.name" placeholder="请输入商家姓名"></el-input>
      </el-form-item>
      <el-form-item label="商家电话：">
        <el-input size="small" v-model="searchForm.mobile" placeholder="请输入商家电话"></el-input>
      </el-form-item>
      <el-form-item label="状态：">
        <el-select v-model="searchForm.status" size="small" placeholder="请选择">
          <el-option label="全部" value=""></el-option>
          <el-option label="待审核" :value="0"></el-option>
          <el-option label="待安装" :value="1"></el-option>
          <el-option label="已完成" :value="2"></el-option>
          <el-option label="已驳回" :value="-1"></el-option>
          <el-option label="安装失败" :value="-2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="申请时间：">
        <el-date-picker size="small" v-model="searchForm.start_time" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
      </el-form-item>
      <el-form-item label=" " label-width="15px">
        <el-button type="primary" size="small" @click="search">搜索</el-button>
        <el-button type="text" size="small" @click="clearSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="sn" label="二维码ID" align="center"></el-table-column>
      <el-table-column prop="name" label="商家姓名" align="center"></el-table-column>
      <el-table-column prop="mobile" label="商家电话" align="center"></el-table-column>
      <el-table-column prop="num" label="安装货柜台数" align="center"></el-table-column>
      <el-table-column label="安装地址" align="center">
        <template v-slot="{ row }">
          <el-button @click="(showAddress = !0), (address_info = row.address_info)" type="text" size="small">查看</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="start_time" label="期待安装日期" align="center">
        <template v-slot="{ row }">{{ getDateformat(row.start_time) }}</template>
      </el-table-column>
      <el-table-column prop="status" label="状态" align="center">
        <template v-slot="{ row }">
          <span :style="{ color: row.status == 0 || row.status == 1 ? '#e6a23c' : row.status == -1 || row.status == -2 ? '#f56c6c' : '#67c23a' }">
            {{ row.status == 0 ? '待审核' : row.status == 1 ? '待安装' : row.status == 2 ? '已完成' : row.status == -1 ? '已驳回' : '安装失败' }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="申请时间" align="center">
        <template v-slot="{ row }">{{ getDateformat(row.create_time) }}</template>
      </el-table-column>
      <el-table-column label="操作" align="center" fixed="right">
        <template v-slot="{ row }">
          <el-button @click="audit(row)" type="text" size="small" v-if="row.status == 1">完成</el-button>
          <el-button @click="reject = row.refuse_reason,showReject = !0" type="text" size="small" v-if="row.status == -2">失败原因</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="total" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData"></Paging>
    <el-dialog title="查看" :visible.sync="showAddress" width="600px">
      <div style="margin-bottom: 10px" v-for="(item, index) in address_info" :key="index">{{ item }}</div>
    </el-dialog>
    <el-dialog title="审核" :visible.sync="showAudit" width="600px">
      <el-form ref="form" label-width="100px">
        <el-form-item label="审核结果：">
          <el-radio-group v-model="status">
            <el-radio :label="2">完成</el-radio>
            <el-radio :label="-2">安装失败</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="失败原因：" v-if="status == -2">
          <el-input v-model="refuse_reason" type="textarea" :rows="5"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showAudit = !1">取 消</el-button>
        <el-button type="primary" @click="confirmAudit">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="驳回原因" :visible.sync="showReject" width="650px">
      {{ reject }}
    </el-dialog>
  </el-main>
</template>

<script>
import _ from 'lodash';
import Paging from '@/components/paging';
import { getDateformat } from '@/util/getDate';
export default {
  components: {
    Paging,
  },
  data() {
    return {
      searchForm: {
        page: 1,
        rows: 10,
        sn: '',
        name: '',
        mobile: '',
        start_time: '',
        end_time: '',
        status: '',
      },
      list: [],
      total: 0,
      showAudit: !1,
      showAddress: !1,
      showReject:!1,
      refuse_reason: '',
      status: 2,
      reject:'',
      id: '',
      address_info: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getDateformat(val) {
      return getDateformat(val);
    },
    search() {
      this.searchForm.page = 1;
      this.getList();
    },
    clearSearch() {
      this.searchForm = {
        page: 1,
        rows: 10,
        sn: '',
        name: '',
        mobile: '',
        start_time: '',
        end_time: '',
        status: '',
      };
      this.getList();
    },
    updateData(val, status) {
      if (status == 0) {
        this.searchForm.rows = val;
      } else {
        this.searchForm.page = val;
      }
      this.getList();
    },
    audit(row) {
      this.id = row.id;
      this.status = 2;
      this.refuse_reason = '';
      this.showAudit = !0;
    },
    confirmAudit() {
      let data = {
        id: this.id,
        status: this.status,
      };
      if (this.status == -2) {
        if (!this.refuse_reason) return this.$message.warning('请填写驳回原因');
        data.refuse_reason = this.refuse_reason;
      }
      this.$axios.post(this.$api.serviceProvider.containerCheck, data).then(res => {
        if (res.code == 0) {
          this.$message.success('处理成功');
          this.getList();
          this.showAudit = !1;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getList() {
      let searchForm = _.cloneDeep(this.searchForm);
      if (searchForm.start_time) {
        searchForm.end_time = searchForm.start_time[1] / 1000;
        searchForm.start_time = searchForm.start_time[0] / 1000;
      }
      this.$axios.post(this.$api.serviceProvider.containerList, searchForm).then(res => {
        if (res.code == 0) {
          this.list = res.result.list;
          this.total = res.result.total;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
}
</style>